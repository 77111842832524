<template>
  <b-card :title="$t('sharedFields.header')">
    <vs-col vs-w="12" class="mb-2">
      {{ $t('sharedFields.description') }}
    </vs-col>
    <vs-col>
      <p>{{ $t('sharedFields.selectCatalog') }}</p>
      <vs-select
        :value="getSelectedCatalog"
        @input="onChangeCatalog($event)"
      >
        <vs-select-item
          v-for="(element) in getCatalogs"
          :key="element.id"
          :text="element.name"
          :value="element.id"
        />
      </vs-select>
    </vs-col>
    <div ref="container">
      <vs-table class="stretchTableOne" :data="[1]">
        <template slot="thead">
          <vs-th v-for="(name, columnIndex) in columnNamesView" :key="columnIndex">
            <span>{{ $t('sharedFields.tableHeaders.' + name) }}</span>
          </vs-th>
        </template>
        <vs-tr v-for="(item,k) in getFields" :key="k">
          <vs-td>
            {{ getFieldTranslation(item) }}
          </vs-td>
          <vs-td>
            <span v-if="getFieldStatus(item)">
              <check-circle-icon />
            </span>
            <span v-else>
              <x-circle-icon />
            </span>
          </vs-td>
          <vs-td>
            <a class="d-b-inline" href="#" @click.prevent="changeValue(item)">
              <vs-tooltip :text="$t('sharedFields.changeTooltip')">
                <refresh-ccw-icon />
              </vs-tooltip>
            </a>
          </vs-td>
        </vs-tr>
      </vs-table>
    </div>
  </b-card>
</template>

<script>
import store from '@/store'
import { RefreshCcwIcon, XCircleIcon, CheckCircleIcon } from 'vue-feather-icons'
import SearchInstanceStore from '@/store/search/searchInstance'

export default {
  components: { RefreshCcwIcon, XCircleIcon, CheckCircleIcon },
  data() {
    return {
      columnNamesView: ['field', 'status', 'actions'],
      catalogId: '',
    }
  },
  computed: {
    getCatalogs() {
      return store.state.sharedFields.availableCatalogs
    },
    getSelectedCatalog() {
      return store.state.sharedFields.selectedCatalog
    },
    getFields() {
      return store.state.sharedFields.availableFields
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
  },
  watch: {

  },
  mounted() {
    if (this.getSelectedCatalog === 0) {
      this.initializeCatalogList()
    }
  },
  methods: {
    onChangeCatalog(catalogId) {
      this.getData(catalogId)
    },
    getFieldTranslation(field) {
      return this.$te(`weightsSettings.fields.${field}`) ? this.$t(`weightsSettings.fields.${field}`) : field
    },
    initializeCatalogList() {
      return store.dispatch('sharedFields/initializeCatalogList', [])
    },
    getData(catalogId) {
      return store.dispatch('sharedFields/getCatalogSharedFields', catalogId)
    },
    getFieldStatus(name) {
      let result = false
      if (store.state.sharedFields.sharedFields.forEach === undefined) {
        return []
      }
      store.state.sharedFields.sharedFields.forEach(sharedField => {
        if (sharedField.field === name) {
          result = true
        }
      })
      return result
    },
    changeValue(name) {
      return store.dispatch('sharedFields/toggle', {
        name,
        catalogId: this.getSelectedCatalog,
      })
    },
  },
}
</script>

<style scoped>

 .stretchTableOne tr td:last-of-type,
 .stretchTableOne tr th:last-of-type{
    text-align: right;
    float:right;
  }
</style>
